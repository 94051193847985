import React from 'react'
import Lucas_Pizza from '../images/Lucas_Pizza.png'
import Rio_Loco from '../images/Rio_Loco.png'
import Queen_Pizza from '../images/Queen_Pizza.png'
import Crazy_Pizza from '../images/Crazy_Pizza.png'
import Vegan_Station from '../images/Vegan_Station.png'
import Old_Tblisi from '../images/Old_Tblisi.png'
import pick_stick01 from '../images/pick_stick-01.png'
import Dobilas_Logo from '../images/Dobilas_Logo-01.png'
import Asian_Fusion from '../images/Asian_Fusion.png'
import Oishi_Sushi from '../images/Oishi_Sushi.png'
import Pizza_station from '../images/Pizza_station.png'
import vOODOO_bISTRO_Logo from '../images/vOODOO_bISTRO_Logo-01.png'
import Poke_to_go from '../images/Poke_to_go.png'
import Pappas_Pizza from '../images/Pappas_pizza.png'
import The_local_bistro from '../images/The_local_bistro.png'
import top_burger from '../images/top_burger.png'
import Wokspot from '../images/Wokspot.png'
import FieryChicks from '../images/Fiery_Chicks.png'
import Grill_House from '../images/Grill_House.png'
import Holamexican from '../images/Holamexican.png'
import Kebabwings from  '../images/kebab&wings.png'
import Pizza_amore from '../images/Pizza_amore.png'
import Pizza_de_la_casa from '../images/Pizza_de_la_casa.png'
import Pizza_Palace from '../images/Pizza_Palace.png'
import Sushi_Artison from '../images/Sushi_Artison.png'
import Naples_Pizza from '../images/Naples_Pizza.png'
import Flower_Gallery from '../images/Flower_Gallery.png'



function Logo() {
    var bgColors = { "Default": "#81b71a",
    "Grey1": "#f5f6fa",
    "Grey2":"#dcdde1",
    "Grey_Title":"#95a5a6",
    "second_block":"#ced6e0",
    "kitchen_title":"#747d8c"
   };
  return (
   <div>
    <div className='container-fluid p-3'>
        <div className='row'>
            <div className='col-md-12'>
               <h2 className='text-center'> mūsų prekiniai ženklai</h2>
                <h5 className='text-center font-weight-bold' style={{color: bgColors.Grey_Title}}>OUR BRANDS</h5>
            </div>
        </div>

    </div>
    <div className='container bg-secondary p-3 shadow bg-body rounded m-3 d-block mx-auto'>
        <div className='row'>
                <div className='col-md-12'>

                <div className='row mt-2'>
                        <div className='col-md-2 col-lg-2' style={{backgroundColor: bgColors.Grey1}}>
                        <img src={Queen_Pizza} alt="Queen_Pizza" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                         <span className='image_text ' style={{color: bgColors.kitchen_title}}>VIRTUVĖ   <br/> CHIWAWA PIZZA</span><br/>
                        
                         </div>  
                        <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                        <img src={Crazy_Pizza} alt="Crazy_Pizza" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text'style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/> DEL GATO PIZZA</span><br/>
                         
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8' style={{backgroundColor: bgColors.Grey1}}>
                               <div className="row ">
                                    <div className=" col-sm-12 col-md-3 "><img src={Lucas_Pizza} alt="Lucas_Pizza" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img></div>
                                    <div className="col-sm-12 col-md-3 "> <img src={Rio_Loco} alt="Rio_Loco" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3 '></img></div>
                                     <div className="col-sm-12 col-md-3 "><img src={Pappas_Pizza} alt="Pappas_Pizza" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3 '></img></div>
                                     <div className="col-sm-12 col-md-3"> <img src={The_local_bistro} alt="The_Local_bistro" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3 '></img></div>
                                <span className='image_text text-center p-3 ' style={{color: bgColors.kitchen_title}}>VIRTUVĖ   <br/> CHARLIE PIZZA</span>
                            </div>   
                        </div> 
                      
                </div>

            </div>
            <div className='col-md-12'>
            <div className='row' >
               
            
                <div className='col-md-2 col-lg-2' style={{backgroundColor: bgColors.Grey1}}   >
                        <img src={Vegan_Station} alt="Vegan_Station" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/> EL BARRIO RESTAURANT</span><br/>
                </div>

                        <div className='col-md-2 col-lg-2' style={{backgroundColor: bgColors.Grey1}} >
                        <img src={Old_Tblisi} alt="Old_Tblisi" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/> GEORGIAN PURI</span><br/>
                       </div>


                         <div className='col-md-2 col-lg-2 ' style={{backgroundColor: bgColors.Grey1}} >
                        <img src={vOODOO_bISTRO_Logo} alt="vooDOO_biSTRO" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/> SKONIS RESTAURANT</span><br/>
                       
                        </div>

                        <div className='col-md-2 col-lg-2' style={{backgroundColor: bgColors.Grey1}}  >
                        <img src={Pizza_station} alt="pizza_station" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>  PEPPERONI PIZZA</span><br/>
                        
                        </div>

                        <div className='col-md-2 col-lg-2' style={{backgroundColor: bgColors.Grey1}} >
                        <img src={Holamexican} alt="Holamexican" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>  Casa De Locos_LT</span><br/>
                        
                        </div>


                        <div className='col-md-2 col-lg-2' style={{backgroundColor: bgColors.Grey1}}  >
                        <img src={Sushi_Artison} alt="Sushi_Artison" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>  Mililitrai</span><br/>
                        
                        </div>
                        </div>
                      
</div>
<div className='col-md-12'>
                    <div className='row mt-2 '>
                         
                    
                <div className='col-md-4 col-lg-4' style={{backgroundColor: bgColors.Grey1}}  >
                        <div className='row' >
                            <div className='col-md-6' style={{backgroundColor: bgColors.Grey1}}  >
                                <img src={pick_stick01} alt="Pick_Stick" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                            </div>
                            <div className='col-md-6' style={{backgroundColor: bgColors.Grey1}}  >
                                 <img src={top_burger} alt="Top_Burger" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                            </div>
                            <span className='image_text ' style={{color: bgColors.kitchen_title}}>VIRTUVĖ   <br/> ITS CORN DOG</span><br/>
                        </div>
                        </div>
                        <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}}  >
                        <img src={Dobilas_Logo} alt="Dobilas" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                        <span className='image_text'style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>  KATPEDELE RESTAURANT</span><br/>
                        </div>
                      
                        <div className='col-md-6 col-lg-6' style={{backgroundColor: bgColors.Grey1}} >
                         <div className="row ">
                                  <div className=" col-sm-12 col-md-4 "><img src={Asian_Fusion} alt="Asian_Fusion" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img></div>
                                    <div className="col-sm-12  col-md-4 "> <img src={Oishi_Sushi} alt="Oishi_Sushi" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3 '></img></div>
                                    <div className="col-sm-12   col-md-4 "> <img src={Poke_to_go} alt="Poke_To_Go" className='img-fluid img-thumbnail  mx-auto d-block  firt_row_image m-3 '></img></div>
                                    <span className='image_text text-center   ' style={{color: bgColors.kitchen_title}}>VIRTUVĖ   <br/> MANAMI</span>
                         </div>
                             
                    </div></div>
                        

                       


                    </div>

            </div>



        
            <div className='row mt-2'>
                         
                    
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Pizza_amore} alt="Pizza_amore" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text'style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/> Piano Piano</span><br/>
                                 </div>
                               
                               
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Flower_Gallery} alt="Flower_Gallery" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/> Bloom Boys</span><br/>
                                 
                                 </div>
         
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Naples_Pizza} alt="Naples_Pizza" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>Pizza Journey</span><br/>
                                 
                                 </div>
         
         
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Kebabwings} alt="Kebabwings" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text'style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>Bonus kebabai</span><br/>
                                 </div>
                               
                               
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Wokspot} alt="Wokspot" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>Wok spot</span><br/>
                                 
                                 </div>
         
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Grill_House} alt="Grill_House" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>Kaktusas</span><br/>
                                 
                                 </div>
         
         
                             </div>


                             <div className='row mt-2'>
                         
                    
                                
         
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Pizza_de_la_casa} alt="Pizza_de_la_casa" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>  Barselonos kvapas</span><br/>
                                 
                                 </div>
         
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={Pizza_Palace} alt="Pizza_Palace" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>Ponas makaronas</span><br/>
                                 
                                 </div>
         
         
                                 <div className='col-md-2 col-lg-2'  style={{backgroundColor: bgColors.Grey1}} >
                                 <img src={FieryChicks} alt="FieryChicks" className='img-fluid img-thumbnail mx-auto d-block  firt_row_image m-3'></img>
                                 <span className='image_text' style={{color: bgColors.kitchen_title}}>VIRTUVĖ  <br/>  Crepes N' Waffles</span><br/>
                                 
                                 </div>
                             
         
                             </div>
         
                     </div>
         

        </div>


   
  );
}

export default Logo