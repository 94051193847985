import React from "react";



function Header() {
    return (
       <div>
        


<div className="hero-image">
   <div className="hero-image-inner">
   <h1><span>Sveiki atvykęį</span></h1>
      <span className="des text-warning">The Cloud Lietuva!</span>
      <span className="des2">Pasiekite pilną restorano potencialą, <br/>pasitelkiant naujausias technologiias. Išplėskite savo verslą <br/>maisto užsakymo sferoje su mažesnėmis išlaidomis bei <br/> optimizuotu marketingu!
Sukurkite virtualią virtuvę jau dabar!</span>
      
      <h1 className="mt-3"><span>Welcome to</span></h1>
      <span className="des text-warning">The Cloud Lithuania</span>
      <span className="des2">Activate your full potential in the virtual restaurant industry <br/> with reduced cost, maximized marketing and state of the <br/> art point of sale system technology</span>
</div>

</div>
        

       
       </div>
    );
}
export default Header;