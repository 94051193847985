import React from 'react'

function Footer() {
  return (
    <section className='Footer_area mt-2'>
 <div className='container-fluid '>
        <div className='row bg-dark'>
            <div className='col-md-12'>
                <p className='text-center text-bg-dark p-3 '>&copy; The Cloud 2022 - 2024</p>

            </div>

        </div>
    </div>
    </section>
   
  )
}

export default Footer