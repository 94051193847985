import React from 'react'
import {useRef} from 'react';
import emailjs from '@emailjs/browser';
import { SocialIcon } from 'react-social-icons';
import {FaPhone,FaEnvelope} from 'react-icons/fa'




function Form() {
    var bgColors = { "Default": "#81b71a",
    "Grey1": "#f5f6fa",
    "Grey2":"#dcdde1",
    "Grey_Title":"#95a5a6"
   };
   
    const form=useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_w59ll1j', 'template_ooxtchf', form.current, '225DfRCQLbJvP5MGz')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      };

  return (
 <div>
    <div className='container-fluid p-3'>
    <div className='row'>
        <div className='col-md-12'>
           <h2 className='text-center'> SUSISIEKITE</h2>
            <h5 className='text-center font-weight-bold' style={{color: bgColors.Grey_Title}}>CONTACT US</h5>
        </div>
    </div>

</div>
        <div className='container-fluid bg-dark p-3'>
            <div className='row'>
             <div className='col-md-6 '>
                   <div className='row'>
                     <div className='col-md-12 d-block mx-auto text-center mt-3'>
                  
                     <h2 className='text-bg-dark text-center '>Susisiekite su mumis</h2>
                     <h2 className=' text-center font-weight-bold' style={{color: bgColors.Grey_Title}} >Get in touch with us</h2>
                     </div>
                     <div className='col-md-12 d-block mx-auto text-center mt-5'>
                        <ul className='list-unstyled'>
                            <li className='link-light p-2'><span className='m-2'><FaPhone/></span> + 370 641 29501</li>
                            <li className='link-light p-2'><span className='m-2'><FaEnvelope/></span>info@thecloud.ae</li>
                        </ul>
                          
                     </div>
                     <div className='col-md-12 d-block mx-auto text-center '>
                     <a href="#" className="link-dark p-2"><SocialIcon url="https://www.facebook.com/The-Cloud-Lithuania-101166262711640/" /></a>
                     <a href="#" className="link-dark p-2"><SocialIcon url="https://www.instagram.com/thecloud.lt/" /></a>
                     <a href="#" className="link-dark p-2"><SocialIcon url="https://www.linkedin.com/showcase/the-cloud-lithuania/" /></a>
                     <a href="#" className="link-dark p-2"><SocialIcon url="https://twitter.com/thecloudae" /></a>
                     <a href="#" className="link-dark p-2"><SocialIcon url="https://youtube.com/@thecloudae" /></a>
                     </div>

                     <div className='col-md-12 d-block mx-auto text-center '>
                       
                         <p  className ="pt-2" style={{color: bgColors.Grey_Title}}>
                            <b> UAB The Cloud LT</b><br/>
                         <b>Įmonės kodas: </b> 305676875<br/>

 <b>Adresas:</b> Kalvariju st. 125, LT-08221 Vilnius <br/>
<b>PVM mokėtojo kodas: </b>LT100013688914 <br/>
<b>Maisto tvarkymo pažymėjimo numeris: </b>130019141
                         </p>
                     </div>
                   </div>
                </div>

                <div className='col-md-6'>
                <form ref={form} onSubmit={sendEmail}>

                           <div className="mt-3">
                               <label for="firstname" className="link-light">Vardas <span className='text-danger'> *</span></label><br/>
                                <label for="firstname" className="link-light"> Name</label>
                                <input type="text"  className="form-control" id="firstname"  placeholder='Enter yout First Name' name="firstname" required/>
                            </div>
                            <div className="mt-3">
                            <label for="lastname" className="link-light">Vardas <span className='text-danger'> *</span></label><br/>
                                <label for="lastname" className="link-light">Last Name</label>
                                <input type="text"  className="form-control" id="lastname"  placeholder='Enter your Last Name' name="lastname" required  />
                                
                            </div>
                            <div className="mt-3">
                            <label for="lastname" className="link-light">El. paštas <span className='text-danger'> *</span></label><br/>
                                <label for="email" className="form-label link-light">Email</label>
                                <input type="email" class="form-control" id="email" placeholder="Enter your Email" name="email" required/>
                            </div>
                                <div className="mt-3">
                                <label for="comment" className="link-light">Komentarai <span className='text-danger'> *</span></label><br/>
                                    <label for="comment" className="form-label link-light">Comment or Message</label>
                                    <textarea className="form-control" id="comment" name="comment" rows="3" required></textarea>
                                </div>
                            <div className="">
                                <button type="submit" className="btn btn-dark mt-2 p-2 m-2 mx-auto d-block border border-white ">Send Message</button>
                            </div>
                </form>
                
                </div>
            </div>

        </div>

</div>
   
  );
}

export default Form