import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import Header from './components/pages/Header';
import Form from "./components/pages/Form";
import Logo from "./components/pages/Logo";
import Footer from "./components/pages/Footer";
import { SocialIcon } from 'react-social-icons';

import '../src/components/Mainstyle/style.css';



function App() {

  useEffect(()=>{
   // console.log('data');
    //ReactGA.initialize('UA-248326750-2');
   // ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log(window.location.pathname)  
    // console.log(window.location.search)
    },[]);

  return (
    <div className="container-fluid p-0">
  <Header></Header>
  <Logo></Logo>
  <Form></Form>
  <Footer></Footer>
    </div>
  );
}

export default App;
